import React from 'react';
import Seo from "../../components/seo";
import BitzerPageHtml from '../../components/OtherPages/PartnersPages/bitzerpage';

function BitzerPage() {
    return (
        <>
            <Seo 
                title="Группа предприятий BITZER"
                description="Официальный партнер компании 'Рефинжиниринг'-Bitzer"
                keywords="Bitzer, компрессоры, партнер"
            />
            <BitzerPageHtml />
        </>
    );
}

export default BitzerPage;