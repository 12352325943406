import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
import BitzerSert from './Serts/Bitzer_Sert.jpg'

const BitzerPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Группа предприятий BITZER</h4>
                                <br/>
                                <p>На протяжении 85 лет компания "BITZER" вносит свой вклад в разработку высокоэффективного холодильного и 
                                    климатического оборудования. Продукция компании отличается высоким качеством и надежностью. Разрабатывая 
                                    новые технологичные компоненты для холодильной промышленности, Bitzer способствует повышению энергоэффективности
                                    и высокой производительности для многих производств мира. Инновационные разработки Компании уделяют большое внимание 
                                    снижению вредного воздействия на окружающую среду. </p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://www.bitzer.de/" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="BITZER website">  
                                    &nbsp;&nbsp;www.bitzer.de
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2}>
                                <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с BITZER"
                                    src={BitzerSert}
                                    width="100%"
                                    />
                                </Zoom>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BitzerPageHtml